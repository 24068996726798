.App {
  text-align: center;
  background-color: #fff;
  height: 100vh;
}

.container {
  padding-top: 60px;
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.App-welcome-icon {
  margin-top: 20px;
}

.App-welcome-btn {
  font-size: 3rem;
  border: 1x solid #1b5481;
  border-radius: 6px;
  background: transparent;
  color: #1b5481;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.welcome-input {
  width: 95%;
}
